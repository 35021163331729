import React, { useEffect, useState } from "react";
import {
  addClientItemToMoodBoard,
  addClientMoodBoardItemValue,
  createClientMoodBoard,
} from "../../../AxiosFunctions/Axiosfunctionality";
import { ClientDataAPI } from "../../../redux/clientSlice";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../../redux/message";
import leftArrow from "../../../assets/svgs/leftArrow.svg";

const ClientAddToMoodBoardButton = ({ clientData, artistImage, artistID }) => {
  const [levelmoodboardPopup, setLevelmoodboardPopup] = useState(0);
  const [moodboardPopup, setMoodboardPopup] = useState(false);
  const [newmoodboardName, setNewMoodBoardName] = useState("");

  const dispatch = useDispatch();
  const handleNewMoodboard = (event) => {
    setNewMoodBoardName(event.target.value);
  };
  const handleCreateClientMoodboard = async () => {
    // Simulate API call to create a new moodboard

    try {
      await createClientMoodBoard({
        userId: clientData._id,
        title: newmoodboardName,
      });
      dispatch(ClientDataAPI({ _id: clientData._id }));

      setNewMoodBoardName("");

      setLevelmoodboardPopup(0);
    } catch (error) {
      console.error("Failed to create moodBoard", error);
    }

    setNewMoodBoardName("");
    setLevelmoodboardPopup(0);
  };

  const handleSelectedMoodboard = async (moodboard) => {
    const newSingleItem = {
      artist: artistID,
      artistImage,
    };

    try {
      const response = await addClientMoodBoardItemValue({
        userId: clientData._id,
        moodBoardId: moodboard._id,

        moodBoardItem: newSingleItem,
      });
      handleCloseModal();
      dispatch(ClientDataAPI({ _id: clientData._id }));
      dispatch(updateOpen(true));
      dispatch(updateMessage("Successfully Added"));
    } catch (error) {
      console.error("Error updating element", error);
    }
  };

  const handleCloseModal = () => {
    setLevelmoodboardPopup(0);
    setMoodboardPopup(false);
  };
  const handleToggleModal = () => {
    setLevelmoodboardPopup(0);
    setMoodboardPopup(!moodboardPopup);
  };
  if (!clientData || !clientData._id) {
    return null;
  }

  return (
    <div style={{ position: "absolute", top: "30px", right: "7%" }}>
      <img
        src={
          !moodboardPopup
            ? "/assets/images/heartMoodboard.png"
            : "/assets/images/crossMoodboard.png"
        }
        style={{ backgroundColor: "#ffffff3d" }}
        onClick={handleToggleModal}
      />
      {moodboardPopup && (
        <div
          className="CustomDropdownMenu"
          style={{
            height: "300px",
            minWidth: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px 20px",
            overflow: "scroll",
          }}
        >
          {levelmoodboardPopup === 0 && (
            <>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "30px" }}
                >
                  MY MOODBOARDS
                </div>
                {clientData.moodBoards.map((moodboard) => (
                  <div
                    key={moodboard._id}
                    className="CustomDropdownItem"
                    style={{
                      borderBottom: "solid 1px #B0B0B0",
                      padding: "10px 0",
                      textAlign: "left",
                    }}
                    onClick={() => handleSelectedMoodboard(moodboard)}
                  >
                    {moodboard.title}
                  </div>
                ))}
              </div>
              <div style={{ display: "flex" }}>
                <button
                  className="talentbutton"
                  style={{ marginRight: "10px", fontSize: "10px" }}
                  onClick={() => setLevelmoodboardPopup(1)}
                >
                  NEW MOODBOARD
                </button>
              </div>
            </>
          )}
          {levelmoodboardPopup === 1 && (
            <>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "30px" }}
                >
                  <button
                    className="mr-2"
                    onClick={() => setLevelmoodboardPopup(0)}
                  >
                    <img src={leftArrow} alt="back" />
                  </button>
                  Create New Moodboard
                </div>
                <input
                  type="text"
                  className="text-field text_h w-input"
                  name="moodboardName"
                  placeholder="Moodboard Name"
                  value={newmoodboardName}
                  required
                  onChange={handleNewMoodboard}
                  style={{ width: "200px" }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="talentbutton"
                  style={{ fontSize: "10px" }}
                  onClick={handleCreateClientMoodboard}
                >
                  CREATE
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ClientAddToMoodBoardButton;
