import axios from "axios";

//for Contacts Functionality

// PRODUCTION URL BACKEND

// export const BASE_URL = "3.128.113.172/api/"
// export const IMAGE_ROUTE = "3.128.113.172/";

// PRODUCTION URL FRONTEND

//export const BASE_URL = "http://3.143.107.63:5000/api/"
//export const IMAGE_ROUTE = "http://3.143.107.63:5000/";

//export const BASE_URL = "http://localhost:5000/api/";
//export const IMAGE_ROUTE = "http://localhost:5000/";

export const BASE_URL = "https://api.kidshannon.com/api/";
export const IMAGE_ROUTE = "https://api.kidshannon.com/";

// export const BASE_URL = "http://3.143.107.63:5000/api/"
// export const IMAGE_ROUTE = "http://3.143.107.63:5000/";

export const logouter = () => {
  localStorage.removeItem("authorization");
  // window.location.href = '/#/admin/signin/'
};

export const logouterArtist = () => {
  localStorage.removeItem("authorization");
  // window.location.href = '/#/artist/signin/'
};
export const logouterClient = () => {
  localStorage.removeItem("authorization");
  // window.location.href = '/#/artist/signin/'
};
export const logouterAgent = () => {
  localStorage.removeItem("authorization");
  window.location.reload();
  // window.location.href = '/#/artist/signin/'
};
export const artistImageDetailedSliceData = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/getAllStatusOneDetailed",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const interestedPropertyEmail = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "properties/propertyInterested",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const uploadFiles = async (files) => {
  // 'files' is now expected to be an array of files
  const formData = new FormData();
  // Append each file in the 'files' array to the formData
  Array.from(files).forEach((file, index) => {
    formData.append(`image`, file); // Here "images[]" is the key that your backend expects for the uploaded files
  });

  try {
    const response = await axios.post(BASE_URL + "upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data; // Assuming the response data includes URLs or other data related to the uploaded files
  } catch (error) {
    // Handle error, maybe by logging out the user or another method
  }
};
export const uploadPdfFunction = async (file) => {
  // 'files' is now expected to be an array of files
  const formData = new FormData();
  // Append each file in the 'files' array to the formData

  formData.append(`pdf`, file); // Here "images[]" is the key that your backend expects for the uploaded files

  try {
    const response = await axios.post(BASE_URL + "upload/pdfUpload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data; // Assuming the response data includes URLs or other data related to the uploaded files
  } catch (error) {
    // Handle error, maybe by logging out the user or another method
  }
};
export const artistImageSliceData = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/getAllStatusOne",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getImageBaseURL = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistImage/base64", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getTypeTwoKeyword = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "keywordKid/getTypeTwoKeywords",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getWorldData = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "worldData/getStateCity",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getGraphicCovers = async (data) => {
  try {
    const response = await axios.get(
      BASE_URL + "graphicNovel/getAllCovers",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getGraphicSequential = async (data) => {
  try {
    const response = await axios.get(
      BASE_URL + "graphicNovel/getAllSequential",
      data
    );
    return response.data;
  } catch (error) {
    console.log("ERROR IN FETCHING API");
  }
};

export const getGraphicInking = async (data) => {
  try {
    const response = await axios.get(
      BASE_URL + "graphicNovel/getAllInking",
      data
    );
    return response.data;
  } catch (error) {
    console.log("ERROR IN FETCHING API");
  }
};

export const getGraphicColoring = async (data) => {
  try {
    const response = await axios.get(
      BASE_URL + "graphicNovel/getAllColoring",
      data
    );
    return response.data;
  } catch (error) {
    console.log("ERROR IN FETCHING API");
  }
};

export const getGraphicLettering = async (data) => {
  try {
    const response = await axios.get(
      BASE_URL + "graphicNovel/getAllLettering",
      data
    );
    return response.data;
  } catch (error) {
    console.log("ERROR IN FETCHING API");
  }
};

export const getAllProperties = async (data) => {
  try {
    const response = await axios.get(
      BASE_URL + "properties/getAllArtists",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getBipocBlack = async (data) => {
  try {
    const response = await axios.get(BASE_URL + "bipoc/getAllBlack", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getAllMedical = async (data) => {
  try {
    const response = await axios.get(BASE_URL + "medical/getAllMedical", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getBipocAsian = async (data) => {
  try {
    const response = await axios.get(BASE_URL + "bipoc/getAllAsian", data);
    return response.data;
  } catch (error) {
    console.log("ERROR IN FETCHING API");
  }
};

export const getBipocLatino = async (data) => {
  try {
    const response = await axios.get(BASE_URL + "bipoc/getAllLatino", data);
    return response.data;
  } catch (error) {
    console.log("ERROR IN FETCHING API");
  }
};

export const getBipocCentralAsia = async (data) => {
  try {
    const response = await axios.get(
      BASE_URL + "bipoc/getAllCentralAsia",
      data
    );
    return response.data;
  } catch (error) {
    console.log("ERROR IN FETCHING API");
  }
};

export const getBipocIndigenous = async (data) => {
  try {
    const response = await axios.get(BASE_URL + "bipoc/getAllIndigenous", data);
    return response.data;
  } catch (error) {
    console.log("ERROR IN FETCHING API");
  }
};

export const getIllustrations = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/getIllustration");
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getCgi = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/getCgi");
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getMedical = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/getMedical");
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getMotion = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/getMotion");
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getPhotography = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/getPhotography");
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getMotions = async (data) => {
  try {
    const response = await axios.get(BASE_URL + "motion/getAllArtists");
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getContacts = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "contact/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const deleteContacts = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "contact/deleteId", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const updateKeyword = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/updateById", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const deleteKeyword = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/deleteId", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const createContact = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "contact/create", data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getArtImages = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistImage/getAll", data);
    return response.data;
  } catch (error) {
    // logouter();
    console.log(error.message);
  }
};

export const getArtist = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistUser/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getClient = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "clientUser/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getAgent = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "agentUser/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getArtistProfileUpdates = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistUser/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getActiveArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUser/getAllClient",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const updateSingleContact = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "contact/updateSingle", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const DArtistUser = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUser/singleDelete",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const UArtistUser = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUser/singleUpdate",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const AArtistUser = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistUser/create", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const DClientUser = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/singleDelete",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const UClientUser = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/singleUpdate",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const AClientUser = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "clientUser/create", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const DAgentUser = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/singleDelete",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const UAgentUser = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/singleUpdate",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const AAgentUser = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "agentUser/create", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const AdminUsers = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "Admin/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const forgetAdmin = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "Admin/forgetPassword", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const UAdminUser = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "Admin/singleUpdate", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const DAdminUser = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "Admin/singleDelete", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const AAdminUser = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "Admin/register", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getCategory = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getCategoryTypeOne = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/getAllTypeOne", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getArtistCategoryTypeOne = async (keyword) => {
  try {
    const response = await axios.post(
      BASE_URL + "keyword/artistCategory",
      keyword
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const addCategory = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "keyword/create", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const orderArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUser/orderArtist",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const similarArtistAdd = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "similarArtistUser/update",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const similarArtistGetAll = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "similarArtistUser/getAll",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const similarArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "similarArtistUser/update",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPortfolioOrder = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/orderPortfolio",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistImageCreate = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistImage/CreateAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const artistBioUpdate = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistUpdates/create", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPWUpdate = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/createPW",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPersonalWorkUpdate = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/createPersonalW",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const artistPropertyUpdate = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/createProperty",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistAwardsUpload = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/createAward",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistMotionVideoUpload = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/createMotion",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistMotionGIFUpload = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/createMotionGIF",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistBioDelete = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/deleteId",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const artistBioGetAll = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistUpdates/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const artistBioApprove = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistUpdates/approve", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPWApprove = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/approvePW",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPersonalWorkApprove = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/approvePersonalWork",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistMotionVideoApprove = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/approveMotionVideo",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistMotionGIFApprove = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/approveMotionGIF",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPropertyApprove = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/approveProperty",

      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistAwardPressApprove = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/approveAwardPress",

      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const artistBioUpdateByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistBioUpdateByAdmin",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPWUpdateByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistPWUpdateByAdmin",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPersonalWorkUpdateByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistPersonalWorkUpdateByAdmin",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const artistPropertyUpdateByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistPropertyUpdateByAdmin",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistAwardPressUpdateByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistAwardPressUpdateByAdmin",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistMotionVideoUpdateByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistMotionVideoUpdateByAdmin",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistMotionGIFUpdateByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistMotionGIFUpdateByAdmin",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPWDeleteByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistPWDeleteByAdmin",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistPersonalWorkDeleteByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistPersonalWorkDeleteByAdmin",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const artistPropertyDeleteByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistPropertyDeleteByAdmin",

      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistMotionDeleteByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistMotionDeleteByAdmin",

      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistAwardPressDeleteByAdmin = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUpdates/artistAwardPressDeleteByAdmin",

      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const keywordImageUpdate = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "keyword/updateKeywordThumbnail",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistImagedelete = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistImage/deleteOne", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistImageToggleVisibility = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/toggleVisibility",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistDataId = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistImage/findId", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const clientDataId = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "clientImage/findId", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const updateArtistBio = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "artistUser/updateBio", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const forgetArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUser/forgetPassword",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const forgetClient = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/forgetPassword",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const forgetAgent = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/forgetPassword",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getByImageId = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/getByImageId",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const changeArtistImageStatus = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/changeStatus",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistIfExist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUser/artistIfExist",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getSingleArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUser/getSingleArtist",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getSingleClient = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/getSingleClient",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getSingleAgent = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/getSingleAgent",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getLoggedInAgent = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/getLoggedInAgent",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const getLoggedInClient = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/getLoggedInClient",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const addFavoriteArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/addFavoriteArtist",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Handle the error appropriately
  }
};

// Function to remove an artist from favorites
export const removeFavoriteArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/removeFavoriteArtist",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Handle the error appropriately
  }
};

// Function to check if an artist is already in favorites
export const checkFavoriteArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/checkFavoriteArtist",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Handle the error appropriately
  }
};

// Function to get all favorite artists for a user
export const getFavoriteArtists = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/getFavoriteArtists",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Handle the error appropriately
  }
};
export const createMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/createMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
export const updateMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/updateMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
//addItemWithValueToMoodBoard
export const addItemWithValueToMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/addItemWithValueToMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
export const getMoodBoards = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/getMoodBoards",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

// Add an item to a mood board
export const addItemToMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/addItemToMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

// Add a value to a mood board item
export const addMoodBoardItemValue = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/addMoodBoardItemValue",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

export const updateMoodBoardItem = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/updateMoodBoardItem",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
// Delete an item from a mood board
export const deleteMoodBoardItem = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/deleteMoodBoardItem",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
export const addShareEditEmailToMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/addShareEditEmailToMoodBoard ",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
export const getMoodBoardsSharedWithMe = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/getMoodBoardsSharedWithMe ",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
export const updateShareEditEmailToMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/updateShareEditEmailToMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

// Delete a mood board
export const deleteMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "agentUser/deleteMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

export const addClientFavoriteArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/addFavoriteArtist",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Handle the error appropriately
  }
};

// Function to remove an artist from favorites
export const removeClientFavoriteArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/removeFavoriteArtist",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Handle the error appropriately
  }
};

// Function to check if an artist is already in favorites
export const checkClientFavoriteArtist = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/checkFavoriteArtist",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Handle the error appropriately
  }
};

// Function to get all favorite artists for a user
export const getClientFavoriteArtists = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/getFavoriteArtists",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Handle the error appropriately
  }
};
export const createClientMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/createMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
export const updateClientMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/updateMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
//addItemWithValueToMoodBoard
export const addClientItemWithValueToMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/addItemWithValueToMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
export const getClientMoodBoards = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/getMoodBoards",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

// Add an item to a mood board
export const addClientItemToMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/addItemToMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

// Add a value to a mood board item
export const addClientMoodBoardItemValue = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/addMoodBoardItemValue",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

export const updateClientMoodBoardItem = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/updateMoodBoardItem",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

export const removeClientMoodBoardItemValue = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/removeMoodBoardItemValue",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
// Delete an item from a mood board
export const deleteClientMoodBoardItem = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/deleteMoodBoardItem",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};

// Delete a mood board
export const deleteClientMoodBoard = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/deleteMoodBoard",
      data
    );
    return response.data;
  } catch (error) {
    logouter(); // Replace with your error handling function
  }
};
export const updateArtistData = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistUser/updateArtist",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const updateClientData = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "clientUser/updateClient",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const updateAgentData = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "agentUser/updateAgent", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
export const LoginAdmin = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "Admin/login", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const changeArtistImageDetailsCopyRight = async (data) => {
  try {
    //     const response = await axios.post(BASE_URL + 'artistImage/updateDataCopyRight', data);
    //     return response.data;
  } catch (error) {
    logouter();
  }
};

export const changeArtistImageCopyRight = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/updateCopyright",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const changeArtistImageKeywords = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/updateKeywords",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const changeArtistImageThumbnail = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/updateThumbnail",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const changeArtistImageDetails = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/updateData",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const changeArtistImageViewed = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/changeViewedData",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getAllBanner = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "banner/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const getAllContents = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "content/getAll", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const artistKeywordBased = async (data) => {
  try {
    const response = await axios.post(
      BASE_URL + "artistImage/getKeywordBased",
      data
    );
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const updateBannerImage = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "banner/singleUpdate", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};

export const updateContents = async (data) => {
  try {
    const response = await axios.post(BASE_URL + "content/singleUpdate", data);
    return response.data;
  } catch (error) {
    logouter();
  }
};
