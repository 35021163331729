import React, { useState, useEffect } from "react";
import PlusIcon from "../../../assets/svgs/plusIcon.svg";
import {
  createMoodBoard,
  deleteMoodBoard,
  updateMoodBoard,
} from "../../../AxiosFunctions/Axiosfunctionality"; // Adjust the path as necessary

import crossSvg from "../../../assets/svgs/crossButton.svg"; // Import your SVG file
import editButton from "../../../assets/svgs/edit.svg"; // Import your SVG file
import MyPopup from "../../../components/myPopup/myPopup";
import { AgentDataAPI } from "../../../redux/agentSlice";
import { useDispatch } from "react-redux";
const AgentTabMoodBoard = ({
  handleTab,
  searchedAgentDetails,
  haveEditAccess,
  moodBoards,
  agentDetails,
}) => {
  const [newMoodBoard, setNewMoodBoard] = useState(false);
  const [newmoodBoardName, setNewmoodBoardName] = useState("");
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [selectedMoodBoard, setSelectedMoodBoard] = useState("");
  const [editMoodBoard, setEditMoodBoard] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setNewmoodBoardName(event.target.value);
  };
  const handleDeleteMoodBoard = async () => {
    try {
      await deleteMoodBoard({
        userId: searchedAgentDetails._id,
        moodBoardId: selectedMoodBoard._id,
      });
      dispatch(AgentDataAPI({ _id: agentDetails._id }));

      setDeleteModalActive(false); // Close the modal
    } catch (error) {
      console.error("Failed to unfavorite the artist:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await createMoodBoard({
        userId: searchedAgentDetails._id,
        title: newmoodBoardName,
      });
      dispatch(AgentDataAPI({ _id: agentDetails._id }));

      setNewmoodBoardName("");
      setNewMoodBoard(false);
    } catch (error) {
      console.error("Failed to create moodBoard", error);
    }
  };
  const handleEditMoodBoardName = async (e) => {
    e.preventDefault();

    try {
      await updateMoodBoard({
        userId: searchedAgentDetails._id,
        moodBoardId: selectedMoodBoard._id,
        title: newmoodBoardName,
      });
      setEditMoodBoard(false);
      dispatch(AgentDataAPI({ _id: agentDetails._id }));
      // Reload the page or update state
    } catch (error) {
      console.error("Failed to update mood board", error);
    }
  };

  const openEditModal = (moodBoard) => {
    setSelectedMoodBoard(moodBoard);
    setNewmoodBoardName(moodBoard.title);
    setEditMoodBoard(true);
  };
  const handleEditMoodBoard = (selectedMoodBoard) => {
    const tabname = selectedMoodBoard.sharedByUserId
      ? "sharedIdeaBoard"
      : "ideaBoard";
    handleTab(tabname, selectedMoodBoard._id, selectedMoodBoard.sharedByUserId);
  };
  function renderMoodBoardImages(moodBoardItems) {
    // Check if moodBoardItems is defined and not empty
    if (!moodBoardItems || moodBoardItems.length === 0) {
      return null; // Return null if no items are available to render
    }

    let validImages = moodBoardItems
      .flatMap((item) => {
        if (
          typeof item.itemValue === "string" &&
          item.itemValue.startsWith("http")
        ) {
          // If itemValue is a direct URL
          return [item.itemValue];
        } else if (Array.isArray(item.itemValue)) {
          // If itemValue is an array of objects containing URLs
          return item.itemValue
            .filter(
              (url) =>
                typeof url.artistImage === "string" &&
                url.artistImage.startsWith("http")
            )
            .map((url) => url.artistImage); // Extracting just the URL as a string
        } else {
          return [];
        }
      })
      .slice(0, 4); // Ensure we only take up to four images
    // Define styles based on the number of images
    const getImageStyle = (index, count) => {
      switch (count) {
        case 1:
          return { width: "100%", height: "100%" }; // One image takes full space
        case 2:
          return { width: "50%", height: "100%" }; // Two images, each takes half width
        case 3:
          if (index < 2) {
            return { width: "50%", height: "50%" }; // First two images half width
          } else {
            return { width: "100%", height: "50%" }; // Third image takes full width
          }
        case 4:
          return { width: "50%", height: "50%" }; // Four images in quarters
        default:
          return { width: "25%", height: "100%" }; // Fallback, should not occur
      }
    };

    return (
      <div style={{ display: "flex", flexWrap: "wrap", height: "100%" }}>
        {validImages.map((url, index) => (
          <img
            key={index}
            src={url}
            alt="Mood board item"
            className="image"
            style={getImageStyle(index, validImages.length)}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="_4cols-v2">
      {haveEditAccess && (
        <label className="addMoodBoard" onClick={() => setNewMoodBoard(true)}>
          <img alt="Plus Icon" src={PlusIcon} />
          <h5 style={{ textAlign: "center" }}>NEW IDEA BOARD</h5>
        </label>
      )}

      {moodBoards.length > 0 &&
        moodBoards.map((moodBoard, index) => (
          <div className="moodBoardcard" key={index}>
            {haveEditAccess && (
              <div className="accessButtonsContainer">
                <img
                  src={editButton}
                  alt="Edit"
                  className="accessButtons"
                  onClick={() => openEditModal(moodBoard)}
                />
                <img
                  src={crossSvg}
                  alt="Unfavorite"
                  className="accessButtons"
                  onClick={() => {
                    setSelectedMoodBoard(moodBoard);
                    setDeleteModalActive(true);
                  }}
                />
              </div>
            )}
            <div
              onClick={() => handleEditMoodBoard(moodBoard)}
              style={{
                height: "200px",
                width: "auto",
                border: "1px solid",
                marginBottom: "10px",
              }}
            >
              {renderMoodBoardImages(moodBoard.moodBoardItems)}
            </div>
            <p onClick={() => handleEditMoodBoard(moodBoard)}>
              {moodBoard.title}
            </p>
          </div>
        ))}

      {newMoodBoard && (
        <div className="modalTutorial">
          <div
            className="containerModal"
            style={{
              width: "700px",
              height: "auto",
              backgroundColor: "white",
              padding: "20px",
            }}
          >
            <div className="modalCloseBtn">
              <button onClick={() => setNewMoodBoard(false)}>×</button>
            </div>
            <h2 style={{ textAlign: "center" }}>NEW IDEA BOARD</h2>
            <form onSubmit={handleSubmit}>
              <label>Name</label>
              <input
                type="text"
                className="text-field text_h w-100 w-input"
                name="moodBoardName"
                value={newmoodBoardName}
                required
                onChange={handleChange}
              />
              <button type="submit" className="mx-1 myBtn active sm mt-3 mb-3">
                CREATE
              </button>
            </form>
          </div>
        </div>
      )}
      {editMoodBoard && (
        <div className="modalTutorial">
          <div
            className="containerModal"
            style={{
              width: "700px",
              height: "auto",
              backgroundColor: "white",
              padding: "20px",
            }}
          >
            <div className="modalCloseBtn">
              <button onClick={() => setEditMoodBoard(false)}>×</button>
            </div>
            <h2 style={{ textAlign: "center" }}>EDIT IDEA BOARD</h2>
            <form onSubmit={handleEditMoodBoardName}>
              <label>Name</label>
              <input
                type="text"
                className="text-field text_h w-100 w-input"
                name="moodBoardName"
                value={newmoodBoardName}
                required
                onChange={handleChange}
              />
              <button type="submit" className="mx-1 myBtn active sm mt-3 mb-3">
                SAVE
              </button>
            </form>
          </div>
        </div>
      )}
      {deleteModalActive && (
        <MyPopup
          BackClose
          onClose={() => setDeleteModalActive(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>
              Delete {selectedMoodBoard.title} Idea Board
            </h2>
            <p>Are you sure you want to delete this Idea Board?</p>
            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button
                className="myBtn active mx-1"
                onClick={handleDeleteMoodBoard}
              >
                DELETE
              </button>
              <button
                className="myBtn mx-1"
                onClick={() => setDeleteModalActive(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </MyPopup>
      )}
    </div>
  );
};

export default AgentTabMoodBoard;
