import React, { useEffect, useState } from "react";
import {
  addItemToMoodBoard,
  addMoodBoardItemValue,
  createMoodBoard,
  getMoodBoardsSharedWithMe,
} from "../../../AxiosFunctions/Axiosfunctionality";
import { AgentDataAPI } from "../../../redux/agentSlice";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../../redux/message";
import leftArrow from "../../../assets/svgs/leftArrow.svg";

const AddToMoodBoardButton = ({ agentData, artistImage, artistID }) => {
  const [levelmoodboardPopup, setLevelmoodboardPopup] = useState(0);
  const [moodboardPopup, setMoodboardPopup] = useState(false);
  const [newmoodboardName, setNewMoodBoardName] = useState("");
  const [selectedMoodboard, setSelectedMoodboard] = useState(null);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);
  const [sharedMoodBoards, setSharedMoodBoards] = useState([]);

  const dispatch = useDispatch();
  const handleNewMoodboard = (event) => {
    setNewMoodBoardName(event.target.value);
  };
  const handleCreateMoodboard = async () => {
    // Simulate API call to create a new moodboard

    try {
      await createMoodBoard({
        userId: agentData._id,
        title: newmoodboardName,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      setNewMoodBoardName("");

      setLevelmoodboardPopup(0);
    } catch (error) {
      console.error("Failed to create moodBoard", error);
    }

    setNewMoodBoardName("");
    setLevelmoodboardPopup(0);
  };
  useEffect(() => {
    if (
      selectedMoodboard &&
      selectedMoodboard._id &&
      agentData &&
      agentData.moodBoards &&
      agentData.moodBoards.length > 0
    ) {
      let matchingMoodBoard = agentData.moodBoards.find(
        (moodBoard) => moodBoard._id === selectedMoodboard._id
      );

      // If no matching mood board is found in agentData, search in sharedMoodBoards
      if (
        !matchingMoodBoard &&
        sharedMoodBoards &&
        sharedMoodBoards.length > 0
      ) {
        matchingMoodBoard = sharedMoodBoards.find(
          (moodBoard) => moodBoard._id === selectedMoodboard._id
        );
      }
      // Check if a matching mood board was found
      if (matchingMoodBoard) {
        setSelectedMoodboard(matchingMoodBoard); // Update the selectedMoodboard with the found mood board
      }
    }
  }, [agentData, sharedMoodBoards]);

  const handleSelectedMoodboard = (moodboard) => {
    setSelectedMoodboard(moodboard);
    setLevelmoodboardPopup(2);
  };
  const handleSelectedElement = async (element) => {
    const userId = selectedMoodboard?.sharedByUserId || agentData._id;
    try {
      const response = await addItemToMoodBoard({
        userId: userId,
        moodBoardId: selectedMoodboard._id,
        itemType: element,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      setLevelmoodboardPopup(2);
    } catch (error) {
      console.error("Error adding new element", error);
    }
    setShowSecondDropdown(false);
  };
  const fetchSharedIdeaBoards = async () => {
    try {
      const response = await getMoodBoardsSharedWithMe({
        userId: agentData._id,
      });
      setSharedMoodBoards(response.data.moodBoards);
    } catch (error) {
      console.error("Error fetching shared idea boards", error);
    }
  };
  useEffect(() => {
    if (agentData && agentData._id) {
      fetchSharedIdeaBoards();
    }
  }, [agentData]);
  const addValueToMoodItem = async (itemId, prevValue) => {
    const newSingleValue = {
      artistID,
      artistImage,
    };
    let newValue;
    if (!prevValue) {
      newValue = [newSingleValue];
    } else {
      newValue = [...prevValue, newSingleValue];
    }
    try {
      const userId = selectedMoodboard?.sharedByUserId || agentData._id;

      const response = await addMoodBoardItemValue({
        userId: userId,
        moodBoardId: selectedMoodboard._id,
        itemId: itemId,

        itemValue: newValue,
      });
      handleCloseModal();
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      await fetchSharedIdeaBoards();
      dispatch(updateOpen(true));
      dispatch(updateMessage("Successfully Added"));
    } catch (error) {
      console.error("Error updating element", error);
    }
  };
  const handleOpenSecondDropdown = () => {
    setShowSecondDropdown(!showSecondDropdown);
  };
  const handleCloseModal = () => {
    setLevelmoodboardPopup(0);
    setMoodboardPopup(false);
  };
  const handleToggleModal = () => {
    setLevelmoodboardPopup(0);
    setMoodboardPopup(!moodboardPopup);
  };
  if (!agentData || !agentData._id) {
    return null;
  }

  return (
    <div style={{ position: "absolute", top: "30px", right: "7%" }}>
      <img
        src={
          !moodboardPopup
            ? "/assets/images/heartMoodboard.png"
            : "/assets/images/crossMoodboard.png"
        }
        style={{ backgroundColor: "#ffffff3d" }}
        onClick={handleToggleModal}
      />
      {moodboardPopup && (
        <div
          className="CustomDropdownMenu"
          style={{
            height: "300px",
            minWidth: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "10px 20px",
            overflow: "scroll",
          }}
        >
          {levelmoodboardPopup === 0 && (
            <>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "5px" }}
                >
                  MY MOODBOARDS
                </div>
                {agentData.moodBoards.map((moodboard) => (
                  <div
                    key={moodboard._id}
                    className="CustomDropdownItem"
                    style={{
                      borderBottom: "solid 1px #B0B0B0",
                      padding: "10px 0",
                      textAlign: "left",
                    }}
                    onClick={() => handleSelectedMoodboard(moodboard)}
                  >
                    {moodboard.title}
                  </div>
                ))}
              </div>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", margin: "10px 0 5px 0" }}
                >
                  Shared MoodBoards
                </div>
                {sharedMoodBoards.map((moodboard) => (
                  <div
                    key={moodboard._id}
                    className="CustomDropdownItem"
                    style={{
                      borderBottom: "solid 1px #B0B0B0",
                      padding: "10px 0",
                      textAlign: "left",
                    }}
                    onClick={() => handleSelectedMoodboard(moodboard)}
                  >
                    {moodboard.title}
                  </div>
                ))}
              </div>
              <div style={{ display: "flex" }}>
                <button
                  className="talentbutton"
                  style={{ marginRight: "10px", fontSize: "10px" }}
                  onClick={() => setLevelmoodboardPopup(1)}
                >
                  NEW MOODBOARD
                </button>
              </div>
            </>
          )}
          {levelmoodboardPopup === 1 && (
            <>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "30px" }}
                >
                  <button
                    className="mr-2"
                    onClick={() => setLevelmoodboardPopup(0)}
                  >
                    <img src={leftArrow} alt="back" />
                  </button>
                  Create New Moodboard
                </div>
                <input
                  type="text"
                  className="text-field text_h w-input"
                  name="moodboardName"
                  placeholder="Moodboard Name"
                  value={newmoodboardName}
                  required
                  onChange={handleNewMoodboard}
                  style={{ width: "200px" }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="talentbutton"
                  style={{ fontSize: "10px" }}
                  onClick={handleCreateMoodboard}
                >
                  CREATE
                </button>
              </div>
            </>
          )}
          {levelmoodboardPopup === 2 && selectedMoodboard && (
            <>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "30px" }}
                >
                  <button
                    className="mr-2"
                    onClick={() => setLevelmoodboardPopup(0)}
                  >
                    <img src={leftArrow} alt="back" />
                  </button>
                  {selectedMoodboard.title}
                </div>
                {selectedMoodboard.moodBoardItems?.map((item, index) => {
                  // Determine if the item should be disabled
                  const isDisabled =
                    item.itemType === "text" ||
                    item.itemType === "upload" ||
                    (item.itemType === "grid_1" &&
                      item.itemValue?.length >= 1) ||
                    (item.itemType === "grid_2" &&
                      item.itemValue?.length >= 2) ||
                    (item.itemType === "grid_3" &&
                      item.itemValue?.length >= 3) ||
                    (item.itemType === "grid_4" && item.itemValue?.length >= 4);
                  return (
                    <div
                      key={index}
                      className={`CustomDropdownItem ${
                        isDisabled ? "disabledItem" : ""
                      }`}
                      onClick={() =>
                        addValueToMoodItem(item._id, item.itemValue)
                      }
                      style={{
                        borderBottom: "solid 1px #B0B0B0",
                        padding: "10px 0",
                        textAlign: "left",
                        backgroundColor: isDisabled ? "#ccc" : "", // Grey out if disabled
                        pointerEvents: isDisabled ? "none" : "auto", // Prevents interaction if disabled
                        opacity: isDisabled ? 0.5 : 1, // Lower opacity if disabled
                      }}
                    >
                      {item.itemName}
                    </div>
                  );
                })}
              </div>
              <div style={{ display: "flex" }}>
                <button
                  className="talentbutton"
                  style={{ marginRight: "10px", fontSize: "10px" }}
                  onClick={() => setLevelmoodboardPopup(3)}
                >
                  NEW MOODBOARD ITEM
                </button>
              </div>
            </>
          )}
          {levelmoodboardPopup === 3 && (
            <>
              <div>
                <div
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "30px" }}
                >
                  <button
                    className="mr-2"
                    onClick={() => setLevelmoodboardPopup(2)}
                  >
                    <img src={leftArrow} alt="back" />
                  </button>
                  Create New Moodboard Item
                </div>
                <div
                  className="CustomDropdownItem"
                  style={{
                    borderBottom: "solid 1px #B0B0B0",
                    padding: "10px 0",
                    textAlign: "left",
                  }}
                  onClick={() => handleOpenSecondDropdown()}
                >
                  GRID
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {showSecondDropdown && (
        <div
          className="CustomDropdownMenu"
          style={{ position: "absolute", right: "20px", top: "110px" }}
        >
          <div>Columns</div>
          <div
            className="CustomDropdownItem"
            onClick={() => handleSelectedElement("grid_1")}
          >
            1
          </div>
          <div
            className="CustomDropdownItem"
            onClick={() => handleSelectedElement("grid_2")}
          >
            2
          </div>
          <div
            className="CustomDropdownItem"
            onClick={() => handleSelectedElement("grid_3")}
          >
            3
          </div>
          <div
            className="CustomDropdownItem"
            onClick={() => handleSelectedElement("grid_4")}
          >
            4
          </div>
        </div>
      )}
    </div>
  );
};

export default AddToMoodBoardButton;
