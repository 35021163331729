import React, { useCallback, useEffect, useRef, useState } from "react";
import AgentProfile from "./agentProfile";
import { getSingleAgent } from "../../../AxiosFunctions/Axiosfunctionality";
import { useHistory } from "react-router-dom";
import loadingImg from "../../../assets/loading.gif";
import { useSelector } from "react-redux";

const AgentUser = ({ agentDetails, agentID }) => {
  const [searchedAgentDetails, setSearchedAgentDetails] = useState(null);
  const [haveEditAccess, setHaveEditAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  // const { AgentDataAPI } = useSelector((state) => state);
  const history = useHistory();
  useEffect(() => {

    if (!agentID && (!agentDetails || Object.keys(agentDetails).length === 0)) {
      history.replace("/myshannon/signin");

    } else if (agentID) {
      // If agentID is there, check for agentDetails
      if (agentDetails) {
        // Set access based on ID match
        setHaveEditAccess(agentDetails._id === agentID);
      } else {
        setHaveEditAccess(false);
      }
    } else if (
      agentDetails &&
      Object.keys(agentDetails).length > 0 &&
      !agentID
    ) {
      // If agentID is not there but agentDetails is
      history.replace(
        `/myshannon/${agentDetails._id}?activeTab=favoriteArtists`
      );
    }
  }, [agentID, agentDetails]);
  const currentRequest = useRef(null);
  const fetchData = useCallback(async () => {
    if (!agentID) return;

    // Abort the previous request if it's still in progress
    if (currentRequest.current) {
      currentRequest.current.abort();
    }

    const controller = new AbortController();
    currentRequest.current = controller;

    setLoading(true);

    try {
      const responseAgent = await getSingleAgent({
        _id: agentID,
        signal: controller.signal,
      });
      if (responseAgent === "NO AGENT FOUND" || responseAgent === "ERROR") {
        setSearchedAgentDetails(null);
      } else {
        setSearchedAgentDetails(responseAgent);
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching agent details:", error);
        setSearchedAgentDetails(null);
      }
    } finally {
      if (!controller.signal.aborted) {
        setLoading(false);
        currentRequest.current = null;
      }
    }
  }, [agentID]);
  useEffect(() => {
    if (agentID && agentDetails?._id !== agentID) {
      fetchData();
    } else if (agentDetails && Object.keys(agentDetails).length > 0) {
      setSearchedAgentDetails(agentDetails);
      setLoading(false);
    }
  }, [agentID, agentDetails, fetchData]);

  if (loading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <img
          className="mb-3"
          alt="loading"
          src={loadingImg}
          style={{ width: "50px" }}
        />
      </div>
    );
  }
  return (
    <div>
      {searchedAgentDetails ? (
        <AgentProfile
          searchedAgentDetails={searchedAgentDetails}
          haveEditAccess={haveEditAccess}
          agentDetails={agentDetails}
        />
      ) : (
        <h1>No Agent Found</h1>
      )}
    </div>
  );
};

export default AgentUser;
